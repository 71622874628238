<template>
  <div class="subpages-block">
    <div class="wrapper">
      <h2 v-if="title.length > 0" class="text-subheader">{{ title }}</h2>

      <div class="subpages-block__list">
        <ClientOnly>
          <Swiper
            :modules="[Pagination, Navigation]"
            :slides-per-view="1.2"
            :space-between="16"
            centered-slides
            :breakpoints="breakpoints"
            :loop="subpages.length > 2"
            navigation
            pagination
          >
            <SwiperSlide
              v-for="(page, index) in subpages"
              :key="`article_subpage_${page.id}_${index}`"
            >
              <CArticleCard
                :image="page.image"
                :image-seo="page.image_seo"
                :preview-text="page.preview_text"
                :publish-from="page.publish_from"
                :slug="page.slug"
                :title="page.title"
                :text="page.preview_text"
                :date="page.publish_from"
              />
            </SwiperSlide>
          </Swiper>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { PageResult } from '@/types/pages';
import './styles.scss';
import CArticleCard from '~/components/sectionBlocks/articleCard/CArticleCard.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper';

interface Props {
  title: string;
  subpages: PageResult[];
}

defineProps<Props>();

const breakpoints = {
  1024: {
    slidesPerView: 3,
    spaceBetween: 24,
    centeredSlides: false,
  },
  520: {
    slidesPerView: 2,
    spaceBetween: 16,
  },
};
</script>

<style scoped></style>
